/* Home Banner Area css
============================================================================================ */
.home_banner_area{
	z-index: 1;
	min-height: 900px;
	display: flex;
	// background: url(../img/banner/home-banner.jpg) no-repeat scroll center center;
	background: url(../img/banner/welcome4.jpg) no-repeat scroll center center;
	background-size: cover;
	.banner_inner{
		width: 100%;
		display: flex;
		.container{
			vertical-align: middle;
			align-self: center;
		}
		.banner_content{
			color: #fff;
			vertical-align: middle;
			align-self: center;
			text-align: center;
			h2{
				margin-top: 0px;
				font-size: 48px;
				font-weight: bold;
				font-family: $hee;
				line-height: 54px;
				text-transform: uppercase;
				margin-bottom: 15px;
			}
			p{
				color: #f9f9ff;
				font-family: $rob;
				max-width: 680px;
				font-size: 16px;
				line-height: 26px;
				margin: 0px auto 35px;
			}
			.banner_map_img{
				text-align: right;
			}
			.banner_btn{

			}
		}
	}

}

.blog_banner{
	min-height: 780px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	margin-bottom: 0px;
	.banner_inner{
		background: #04091e;
		position: relative;
		overflow: hidden;
		width: 100%;
		min-height: 780px;
		z-index: 1;
		.overlay{
			// background: url(../img/banner/banner-2.jpg) no-repeat scroll center center;
			opacity: .5;
			height: 125%;
			position: absolute;
			left: 0px;
			top: 0px;
			width: 100%;
			z-index: -1;
		}
		.blog_b_text{
			max-width: 700px;
			margin: auto;
			color: #fff;
			margin-top: 40px;
			h2{
				font-size: 60px;
				font-weight: 500;
				font-family: $rob;
				line-height: 66px;
				margin-bottom: 15px;
			}
			p{
				font-size: 16px;
				margin-bottom: 35px;
			}
			.white_bg_btn{
				line-height: 42px;
				padding: 0px 45px;
			}
		}
	}
}

.banner_box{
	max-width: 1620px;
	margin: auto;
}
.banner_area{
	position: relative;
	z-index: 1;
	min-height: 400px;
	.banner_inner{
		position: relative;
		overflow: hidden;
		width: 100%;
		min-height: 400px;
		background: #000;
		z-index: 1;
		.overlay{
			background: url(../img/banner/welcome2.png) no-repeat scroll center center;
			//background: url(../img/banner/banner.jpg) no-repeat scroll center center;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			height: 125%;
			bottom: 0;
			z-index: -1;
			opacity: 1;
		}
		.banner_content{
			margin-top: 55px;
			h2{
				color: #fff;
				font-size: 48px;
				font-family: $rob;
				margin-bottom: 0px;
				font-weight: 500;
			}
			.page_link{
				a{
					font-size: 14px;
					color: #fff;
					font-family: $rob;
					margin-right: 32px;
					position: relative;
					text-transform: uppercase;
					&:before{
						content: "\f0da";
						font: normal normal normal 14px/1 FontAwesome;
						position: absolute;
						right: -25px;
						top: 50%;
						transform: translateY(-50%);
					}
					&:last-child{
						margin-right: 0px;
						&:before{
							display: none;
						}
					}
				}
			}
		}
	}
}

/* End Home Banner Area css
============================================================================================ */
