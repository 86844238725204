/* Team Area css
============================================================================================ */
.mission_statement_area {
}
.team_area {
  background: #f9f9ff;
}

.team_inner {
  margin-bottom: -50px;
}

.team_item {
  position: relative;
  text-align: center;
  margin-bottom: 50px;

  .team_img {
    position: relative;
    overflow: hidden;

    .hover {
      display: none;
      position: absolute;
      left: 0;
      width: 100%;
      bottom: -70px;
      text-align: center;
      @include transition;
      background: #fff;

      a {
        font-size: 14px;
        color: #dddddd;
        margin-right: 20px;
        line-height: 50px;

        &:last-child {
          margin-right: 0px;
        }

        &:hover {
          i {
            &:before {
              background: linear-gradient(to right, #2c28b1 0%, #9b5cf6 70%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
    }
  }

  .team_name {
    text-align: center;
    margin-top: 20px;

    h4 {
      margin-bottom: 5px;
      color: $dip;
      font-family: $rob;
      font-weight: 500;
      font-size: 20px;
      @include transition;
    }

    p {
      font-size: 14px;
      font-family: $rob;
      color: $pfont;
      margin-bottom: 0;
    }
  }

  @media (min-width: 992px) {
    &:hover {
      .team_img {
        &:before {
          opacity: 1;
        }

        .hover {
          display: block;
          bottom: 0;
        }
      }

      .team_name {
        h4 {
          background: linear-gradient(to right, #2c28b1 0%, #9b5cf6 70%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}

/* End Team Area css
============================================================================================ */
