.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
iframe {
  width: 560px;
  height: 315px;
}

.ical-link {
  font-size: 14px;
}

@media screen and (max-width: 600px) {
  iframe {
    width: 420px;
    height: 236px;
  }
}

@media screen and (max-width: 460px) {
  .ical-link {
    font-size: 13px;
  }
  iframe {
    width: 390px;
    height: 219px;
  }
}

@media screen and (max-width: 430px) {
  .ical-link {
    font-size: 12px;
  }
  iframe {
    width: 360px;
    height: 203px;
  }
}

@media screen and (max-width: 400px) {
  .ical-link {
    font-size: 11px;
  }
  iframe {
    width: 340px;
    height: 191px;
  }
}

@media screen and (max-width: 380px) {
  .ical-link {
    font-size: 10px;
  }
  iframe {
    width: 320px;
    height: 180px;
  }
}

@media screen and (max-width: 360px) {
  .ical-link {
    font-size: 9px;
  }
  iframe {
    width: 290px;
    height: 163px;
  }
}

@media screen and (max-width: 330px) {
  .ical-link {
    font-size: 8px;
  }
  iframe {
    width: auto;
    height: auto;
  }
}

.weather-temp{
  padding: 3px 5px;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  background-color: #4e4d4a;
  border-radius: 10px!important;
}
